<template>
  <div class="container" ref="wrapper">
    <!-- 12312313213132 -->
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-nav-bar :title="title" left-arrow @click-left="$router.push('/')">
        <template v-slot:right>
          <div @click="toadd()">
            <van-icon name="plus" size="16" />
            <div style="float: right; margin-left: 5px; font-size: 15px">
              我要授课
            </div>
          </div>
        </template>
      </van-nav-bar>

      <van-row>
        <van-col span="24" style="margin-top: 5px">
          <van-search
            v-model="keyword"
            @search="onSearch"
            shape="round"
            placeholder="请输入搜索关键词"
          />
        </van-col>
      </van-row>

      <div v-for="(seting, setingindex) in setingBase" :key="setingindex">
        <!-- 轮播图 -->
        <div v-if="seting.isShow && seting.setType == 200">
          <van-swipe
            class="my-swipe"
            :autoplay="3000"
            indicator-color="white"
            v-if="swiperList.length > 0"
          >
            <van-swipe-item
              v-for="item in swiperList"
              :key="item.id"
              @click="tabClick(item.urlPath)"
            >
              <img :src="item.imageUrl" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div v-if="seting.isShow && seting.setType == 299">
          <div class="learn">
            <span @click="$router.push({ path: 'user/usercoursetime' })"
              >学习统计</span
            ><span @click="$router.push({ path: '/course/LearnRankList' })"
              >学习排行榜</span
            >
          </div>
        </div>
        <div v-if="seting.isShow && seting.setType == 201">
          <!-- 功能模块 -->
          <van-divider />
          <template>
            <van-grid :border="false" :column-num="4">
              <van-grid-item
                icon="photo-o"
                :text="item.value"
                v-for="item in NavList"
                :key="item.id"
                @click="navClick(item.urlPath)"
              >
                <van-image :src="item.imageUrl" style="width: 20%" />
                {{ item.value }}
              </van-grid-item>
            </van-grid>
          </template>
        </div>
        <div v-if="seting.isShow && seting.setType == 202">
          <van-divider />
          <van-notice-bar
            v-if="LearnTask != ''"
            scrollable
            left-icon="volume-o"
            :text="LearnTask"
            background="#fff"
          />
        </div>
        <div v-if="seting.isShow && seting.setType == 203">
          <div class="goods" v-if="goods.length > 0">
            <van-row>
              <van-col span="18">
                <h2 style="padding: 2px 1px 5px 18px">精品专区</h2>
              </van-col>
              <!-- <van-col span="6">
          <div
            style="float: right; padding: 8px 20px 0px 0px"
            @click="
              $router.push({
                path: '/course/CourseDesignGoods',
              })
            "
          >
            更多<van-icon name="arrow" /></div
        ></van-col> -->
            </van-row>

            <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
            <!-- <lazy-component> -->
            <van-row>
              <van-col
                span="12"
                v-for="item in goods"
                :key="item.id"
                @click="tabClick(item.urlPath)"
              >
                <van-image
                  lazy-load
                  class="w100"
                  fit="contain"
                  style="height: 100px"
                  :src="item.imageUrl"
                />

                <div style="height: 28px; padding: 8px 10px">
                  <span
                    style="display: inline-block; height: 21px"
                    class="van-multi-ellipsis--l3"
                  >
                    {{ item.value }}
                  </span>
                </div>
                <div class="mydivider"></div>
              </van-col>
            </van-row>
            <!-- </lazy-component>
      </van-pull-refresh> -->
          </div>
        </div>
        <div v-if="seting.isShow && seting.setType == 204">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">课程包</h2>
            </van-col>
            <van-col span="6">
              <div
                style="float: right; padding: 8px 20px 0px 0px"
                @click="
                  $router.push({
                    path: '/course/coursePackets',
                  })
                "
              >
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>
          <van-row>
            <van-col
              span="12"
              v-for="item in coursepackets"
              :key="item.id"
              @click="tabClick(item.urlPath)"
            >
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="height: 100px"
                :src="item.imageUrl"
              />

              <div style="height: 28px; padding: 8px 10px">
                <span
                  style="display: inline-block; height: 21px"
                  class="van-multi-ellipsis--l3"
                >
                  {{ item.value }}
                </span>
              </div>

              <div class="mydivider"></div>
            </van-col>
          </van-row>
        </div>
        <div v-if="seting.isShow && seting.setType == 209">
          <van-row>
            <van-col span="18">
              <h2 style="padding: 2px 1px 5px 18px">热门讲师</h2>
            </van-col>
            <van-col span="6">
              <div
                style="float: right; padding: 8px 20px 0px 0px"
                @click="
                  $router.push({
                    path: '/course/teachers',
                  })
                "
              >
                更多<van-icon name="arrow" /></div
            ></van-col>
          </van-row>
          <van-row>
            <van-col
              span="8"
              v-for="item in popularTeacher"
              :key="item.id"
              @click="tabClick(item.urlPath)"
            >
              <div style="height: 200px; padding: 0px 10px">
                <van-image
                  lazy-load
                  class="w100"
                  fit="cover"
                  :src="item.imageUrl"
                />
              </div>
              <div style="height: 25px; padding: 0px 10px">
                <div style="float: left">
                  <span
                    style="display: inline-block; height: 21px"
                    class="van-multi-ellipsis--l3"
                  >
                    {{ item.value }}
                  </span>
                </div>
                <div style="float: right">
                  <van-tag round color="#9d1d22" size="medium"
                    >点击前往</van-tag
                  >
                </div>
              </div>
              <div class="mydivider"></div>
            </van-col>
          </van-row>
        </div>
        <div v-if="seting.isShow && seting.setType == 210">
          <div v-if="popularCourse.length > 0">
            <van-row>
              <van-col span="18">
                <h2 style="padding: 2px 1px 5px 18px">中酿学院 线上课程</h2>
              </van-col>
              <van-col span="6" @click="tabClick('/course/list?type=a')">
                <div style="float: right; padding: 8px 20px 0px 0px">
                  更多<van-icon name="arrow" /></div
              ></van-col>
            </van-row>

            <van-list>
              <van-cell
                v-for="(h, index) in popularCourse"
                :key="index"
                @click="tabClick(h.urlPath)"
              >
                <van-col span="6"
                  ><van-image
                    lazy-load
                    class="w100"
                    fit="contain"
                    style="max-height: 200px"
                    :src="h.imageUrl"
                  >
                  </van-image>
                </van-col>
                <van-col span="18" align="bottom">
                  <div
                    style="display: table-cell; vertical-align: middle"
                    class="zntitle van-multi-ellipsis--l3"
                  >
                    <h3 style="display: inline-block">{{ h.value }}</h3>
                  </div>
                  <!-- <div style="float: right">{{ h.creationTime }}</div> -->
                  <div style="float: right; margin-right: 13px">
                    浏览{{ h.readCount }}人次
                  </div>
                </van-col>
              </van-cell>
            </van-list>
          </div>
        </div>
        <div v-if="seting.isShow && seting.setType == 211">
          <div v-if="popularOfflineCourse.length > 0">
            <van-row>
              <van-col span="18">
                <h2 style="padding: 2px 1px 5px 18px">中酿学院 线下课程</h2>
              </van-col>
              <van-col span="6" @click="tabClick('/course/list?type=b')">
                <div style="float: right; padding: 8px 20px 0px 0px">
                  更多<van-icon name="arrow" /></div
              ></van-col>
            </van-row>

            <van-list>
              <van-cell
                v-for="(h, index) in popularOfflineCourse"
                :key="index"
                @click="tabClick(h.urlPath)"
              >
                <van-col span="6">
                  <van-image
                    lazy-load
                    class="w100"
                    fit="contain"
                    style="max-height: 200px"
                    :src="h.imageUrl"
                  >
                  </van-image>
                </van-col>
                <van-col span="18" align="bottom">
                  <div
                    style="display: table-cell; vertical-align: middle"
                    class="zntitle van-multi-ellipsis--l3"
                  >
                    <h3 style="display: inline-block">{{ h.value }}</h3>
                  </div>
                  <!-- <div style="float: right">{{ h.creationTime }}</div> -->
                  <div style="float: right; margin-right: 13px">
                    浏览{{ h.readCount }}人次
                  </div>
                </van-col>
              </van-cell>
            </van-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getSchoolListAPI, getSetingBase } from "../../api/seting";
export default {
  name: "s7strategy",
  data() {
    return {
      setingBase: [],
      keyword: "",
      title: "中酿学院",
      body: {
        fromType: 1,
        current: 1,
        pageSize: 100,
        FilterData: {
          setTypes: [200, 201, 202, 203, 204, 205, 209, 210, 211],
        },
      },
      loading: true,
      //   接口全部数据
      List: [],
      // 轮播图
      swiperList: [],
      //   功能导航栏
      NavList: [],
      //   下拉刷新
      isLoading: false,
      // 学习任务
      LearnTask: "",
      // 精选专区
      goods: [],
      // 课程包
      coursepackets: [],

      //推荐讲师
      popularTeacher: [],
      //推荐 线上课
      popularCourse: [],
      //推荐 线下课
      popularOfflineCourse: [],
    };
  },
  computed: {},
  created() {
    this.getSchoolList();
    // this.loading = false;
  },
  activated() {
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  watch: {},
  methods: {
    // 拿到数据
    async getSchoolList() {
      //获取功能模块顺序
      let resultBase = await getSetingBase({
        fromType: 1,
        current: 1,
        pageSize: 1000,
        FilterData: { pageType: 2 },
      });
      if (resultBase.data.data.length > 0) {
        this.setingBase = resultBase.data.data;
      }

      let result = await getSchoolListAPI(this.body);
      let { data } = result.data;

      for (let index = 0; index < data.length; index++) {
        if (data[index].setType == 200) {
          this.swiperList.push(data[index]);
        }
        if (data[index].setType == 201) {
          this.NavList.push(data[index]);
          this.NavList = this.NavList.reverse();
        }
        if (data[index].setType == 202) {
          this.LearnTask = data[index].value;
        }
        if (data[index].setType == 203) {
          this.goods.push(data[index]);
        }
        if (data[index].setType == 204) {
          this.coursepackets.push(data[index]);
        }
        if (data[index].setType == 209) {
          this.popularTeacher.push(data[index]);
        }
        if (data[index].setType == 210) {
          this.popularCourse.push(data[index]);
        }
        if (data[index].setType == 211) {
          this.popularOfflineCourse.push(data[index]);
        }
      }
      this.loading = false;
    },
    toadd() {
      this.$router.push({ path: "/course/add" });
    },
    // 功能页跳转
    navClick(nav) {
      this.$router.push(nav);
      //存储 scrollTop 的值
      this.scroll = this.$refs.wrapper.scrollTop;
    },
    onSearch() {
      this.$router.push({
        path: "/search/list",
        query: { q: this.keyword, type: 1 },
      });
    },
    // 加小图标
    // addicon(){
    //     this.$set(this.NavList,0,)
    // },
    // hotClick(h) {
    //   if (h.link != "") {
    //     this.$router.push(h.link);
    //     //阅读量加1
    //     updateReadCount(h.id);

    //     //存储 scrollTop 的值
    //     this.scroll = this.$refs.wrapper.scrollTop;
    //   }
    // },
    // 跳转
    tabClick(link) {
      if (link != "") {
        if (link.indexOf("http") >= 0) {
          window.location.href = link;
        } else {
          this.$router.push(link);

          //存储 scrollTop 的值
          this.scroll = this.$refs.wrapper.scrollTop;
        }
      }
    },
    onRefresh() {
      //   this.List = [];
      //   this.swiperList = [];
      //   this.NavList = [];
      //     List= [],
      //   // 轮播图
      //   swiperList= [],
      //   //   功能导航栏
      //   NavList= [],
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.w100 {
    margin: 2px 8px 2px 8px;
    /deep/ img {
      width: 100%;
      max-height: 180px;
    }
  }
  /deep/.van-grid-item__content {
    padding: 16px 3px;
  }
}
@media (min-width: 200px) {
  .my-swipe .van-swipe-item {
    width: 100%;
    height: 150px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    //# background-color: #39a9ed;
  }

  .my-swipe img {
    width: 100%;
    height: 100%;
  }
  display: inline-block;
}
@media (min-width: 700px) {
  .my-swipe .van-swipe-item {
    width: 100%;
    height: 300px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    //# background-color: #39a9ed;
  }

  .my-swipe img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
.loading {
  text-align: center;
}
.mydivider {
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 10px 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
  border-width: 0;
}
.mydivider::before {
  content: "";
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-color: inherit;
  border-style: inherit;
  border-width: 1px 0 0;
}

.learn {
  padding-top: 15px;
  display: flex;
  justify-content: space-around;
  height: 10px;
  align-items: center;
  //   text-align: center;
}
.learn-task {
  //   display: flex;
  height: 10px;
  //   .van-notice-bar {
  //     // width: 100%;
  //     height: 10px;
  //   }
}
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 30px;
}
.container {
  margin-bottom: 1;
  height: 100%;
  //overflow-y: auto;
  //box-sizing: border-box;

  /deep/.van-tabs {
    height: 100%;
  }
  /deep/.van-tabs__content {
    height: 100%;
  }
}
.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;
    .van-tab {
      line-height: 36px;
    }
    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }
  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }
  /deep/ .van-tab__pane {
    height: 100%;
    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.tabcontent {
  height: 100%;
  margin-bottom: 138px;
}

.acontent {
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;

  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }
  /deep/ ol li {
    list-style-position: outside;
  }
  /deep/ img {
    max-width: 33% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
  /deep/  p {
    padding: revert;
    margin: revert;
}
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
